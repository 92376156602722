// External Dependencies
import { Link } from "@remix-run/react";

const TextLink = ({
  children,
  disabled = false,
  external = false,
  href = null,
  icon = null,
  iconLeft = true,
  onClick = null,
  size,
  styles = "",
}) => {
  const disabledClasses = disabled
    ? "cursor-auto opacity-80 text-gray-200"
    : "text-gray-600 hover:underline";
  const textSizeClasses = size === "sm" ? "text-sm" : "text-md";

  if (href) {
    return external ? (
      <a
        href={href}
        className={`relative font-medium ${
          icon && !iconLeft ? "pr-6" : ""
        } ${disabledClasses} ${textSizeClasses} ${styles} text-primary-600`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(event) => {
          if (onClick) onClick(event);
        }}
      >
        {icon && iconLeft ? (
          <div className="mr-1 flex h-6 w-6 items-center justify-center">
            {icon}
          </div>
        ) : null}
        {children}
        {icon && !iconLeft ? (
          <div className="absolute right-0 top-1/2 flex h-6 w-6 -translate-y-1/2 transform items-center">
            {icon}
          </div>
        ) : null}
      </a>
    ) : (
      <Link
        to={href}
        prefetch="intent"
        className={`relative font-bold ${
          icon && !iconLeft ? "pr-6" : ""
        } ${disabledClasses} ${textSizeClasses} ${styles}`}
        onClick={(event) => {
          if (onClick) onClick(event);
        }}
      >
        {icon && iconLeft ? (
          <div className="mr-1 flex h-6 w-6 items-center justify-center">
            {icon}
          </div>
        ) : null}
        {children}
        {icon && !iconLeft ? (
          <div className="absolute right-0 top-1/2 ml-2 flex h-6 w-6 -translate-y-1/2 transform items-center">
            {icon}
          </div>
        ) : null}
      </Link>
    );
  }

  return (
    <div
      className={`relative cursor-pointer font-bold ${
        icon && !iconLeft ? "pr-6" : ""
      } ${disabledClasses} ${textSizeClasses} ${styles}`}
      onClick={(event) => {
        if (onClick) onClick(event);
      }}
    >
      {icon && iconLeft ? (
        <div className="mr-1 flex h-6 w-6 items-center justify-center">
          {icon}
        </div>
      ) : null}
      {children}
      {icon && !iconLeft ? (
        <div className="absolute right-0 top-1/2 flex h-6 w-6 -translate-y-1/2 transform items-center">
          {icon}
        </div>
      ) : null}
    </div>
  );
};

export default TextLink;
